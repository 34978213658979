import type { Space } from '@sb/routine-runner';

import { getGlobalSpaceService } from './utils/getGlobalSpaceService';

export async function updateGlobalSpace(
  globalSpaceID: string,
  updatedSpaceItem: Space.Item,
): Promise<void> {
  await getGlobalSpaceService().patch(globalSpaceID, {
    spaceItem: updatedSpaceItem,
  });
}
