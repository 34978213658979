import * as zod from 'zod';

import {
  OR2FG7FingerOrientation,
  OR_2FG7_FINGERTIP_OFFSET_METERS_DEFAULT,
  OR_2FG7_FINGER_ANGLE_DEGREES_DEFAULT,
  OR_2FG7_FINGER_POSITION_DEFAULT,
} from '../constants';

// database equipment configuration
export const OnRobot2FG7FingerConfiguration = zod
  .object({
    mountingPosition: OR2FG7FingerOrientation.default(
      OR_2FG7_FINGER_POSITION_DEFAULT,
    ),
    // offsetInMeters = fingertipDiameter
    offsetInMeters: zod
      .number()
      .default(OR_2FG7_FINGERTIP_OFFSET_METERS_DEFAULT),
    fingerAngle: zod.number().default(OR_2FG7_FINGER_ANGLE_DEGREES_DEFAULT),
  })
  .default({});

export type OnRobot2FG7FingerConfiguration = zod.infer<
  typeof OnRobot2FG7FingerConfiguration
>;
