import * as zod from 'zod';

import { ActionRequiredError, Step } from '@sb/remote-control/types';
import convertJavaScriptExpressionToUser from '@sb/remote-control/util/expressions/convertJavaScriptExpressionToUser';
import { validateJSExpression } from '@sb/remote-control/util/expressions/validateJSExpression';
import { Expression, describeExpression } from '@sb/routine-runner';

export namespace SetEnvironmentVariableStep {
  export const name = 'Set variable';
  export const description = 'Sets the value of an environment variable';
  export const librarySection = Step.LibrarySection.Control;
  export const librarySort = '5';
  export const argumentKind = 'SetEnvironmentVariable';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    variableName: zod.string().optional(),
    variableID: zod.string().optional(),
    newValue: Expression.optional(),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    if (!args.variableName) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Variable Name required',
        fieldId: 'variableName',
      });
    }

    if (!args.variableID) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Variable ID required',
        fieldId: 'variableID',
      });
    }

    if (!args.newValue) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Value is required',
        fieldId: 'newValue',
      });
    }

    validateJSExpression(args.newValue, 'newValue', 'New value');

    return {
      ...stepData,
      stepKind: 'SetEnvironmentVariable',
      args: {
        variableName: args.variableName,
        variableID: args.variableID,
        newValue: args.newValue,
      },
    };
  };

  export const getStepDescription: Step.GetStepDescription = ({
    stepConfiguration: { args },
    routine,
    includeStepName,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      return null;
    }

    const { variableID, newValue } = args;

    const variable = routine?.environmentVariables.find(
      (v) => v.id === variableID,
    );

    if (variable && newValue && newValue.kind === 'JavaScript') {
      const jsExprStr = convertJavaScriptExpressionToUser(newValue.expression, {
        isForStepDescription: true,
      });

      return `${includeStepName ? 'Set variable ' : ''}${
        variable.name
      } to value ${jsExprStr}`;
    }

    if (variable && newValue) {
      return `${includeStepName ? 'Set variable ' : ''}${
        variable.name
      } to value ${describeExpression(newValue)}`;
    }

    return null;
  };
}

SetEnvironmentVariableStep satisfies Step.StepKindInfo;
