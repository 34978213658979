import * as zod from 'zod';

import { CartesianPose } from '@sb/geometry';

import { OnRobotConnectionConfiguration } from '../../shared/types/Configuration';
import { OR2FG14_DEFAULT_TCP_TRANSFORM, OR2FG14_SHORTNAME } from '../constants';

import { OnRobot2FG14FingerConfiguration } from './OnRobot2FG14FingerConfiguration';

export const OnRobot2FG14Configuration = zod.object({
  kind: zod.literal('OnRobot2FG14'),
  name: zod.string().default(OR2FG14_SHORTNAME),
  fingerConfiguration: OnRobot2FG14FingerConfiguration,
  connectionOptions: OnRobotConnectionConfiguration,
  tcpTransform: CartesianPose.default(OR2FG14_DEFAULT_TCP_TRANSFORM),
});

export type OnRobot2FG14Configuration = zod.infer<
  typeof OnRobot2FG14Configuration
>;

export const ON_ROBOT_2FG14_GRIPPERS_CONFIGURATION_DEFAULT =
  OnRobot2FG14Configuration.parse({ kind: 'OnRobot2FG14' });
