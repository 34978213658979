import * as zod from 'zod';

import { OR2FG14GripKind, OR2FG14Speed } from '../constants';

export const OnRobot2FG14Command = zod.object({
  kind: zod.literal('OnRobot2FG14Command'),
  /**
   * This field sets whether the grip will be inward or outward.
   * It also sets the if the width is measured from the inside of the fingertips
   * (inward grip) or from the outside of the fingertips (outward grip).
   */
  gripKind: OR2FG14GripKind,

  /**
   * This field sets the target force to be reached when gripping and holding a
   * workpiece in Newtons.
   * If not provided, a sensible default is chosen for moving but not gripping.
   */
  targetForce: zod.number().optional(),

  targetDiameter: zod.number(),

  /**
   * How close to the target do we need to be to consider it a success?
   */
  targetDiameterTolerance: zod.number().optional(),

  targetSpeed: OR2FG14Speed,

  /**
   * This field will fail the actuation if the force grip is not detected
   * after actuating a grip command.
   */
  waitForGripToContinue: zod.boolean().optional(),
});

export type OnRobot2FG14Command = zod.infer<typeof OnRobot2FG14Command>;
