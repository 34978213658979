import type { IntegrationAction } from '../types';

export function isIntegrationActionConfigured(action: IntegrationAction) {
  switch (action.interface.kind) {
    case 'controlBoxIO': {
      return (
        action.interface.outputs.length > 0 &&
        action.interface.outputs.every((o) => o.ioPort !== null)
      );
    }
    case 'modbusTCP': {
      return action.interface.operations.length > 0;
    }
    case 'haasNGC': {
      return action.interface.program !== '';
    }
    default:
      return false;
  }
}
