import * as zod from 'zod';

import { WaitForConfirmationModes } from '@sb/routine-runner/types/WaitForConfirmation';

export default zod.object({
  // a delay after the confirmation to allow the user to
  // move out of the way after pressing the button
  delayMS: zod.number().nonnegative(),
  message: zod.string(),
  mode: WaitForConfirmationModes.default('confirm'),
  choices: zod.array(zod.string()).default([]),
  required: zod.boolean().default(false),
});
