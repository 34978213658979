import WebSocket from 'isomorphic-ws';
import { LRUCache } from 'lru-cache';

import { makeNamespacedLog } from '@sb/log';
import { EventEmitter, wait } from '@sb/utilities';
import type { CacheBackend } from '@sb/utilities/src/ReadThroughCache';
import { ReadThroughCache } from '@sb/utilities/src/ReadThroughCache';

import type { AnyMotionPlanRequest } from './AnyMotionPlanRequest';
import type { ForwardKinematicsRequest } from './ForwardKinematicsRequest';
import { ForwardKinematicsResponse } from './ForwardKinematicsResponse';
import { getMotionPlanRequestCacheKey } from './getMotionPlanRequestCacheKey';
import type { InverseKinematicsRequest } from './InverseKinematicsRequest';
import type { InverseKinematicsSolution } from './InverseKinematicsSolution';
import { MotionPlanGripperStep, MotionPlanArmStep } from './MotionPlan';
import type {
  DeviceKinematics,
  MotionPlannerInterface,
} from './MotionPlannerInterface';
import type { MotionPlanRequest } from './MotionPlanRequest';
import { MotionPlanResponse } from './MotionPlanResponse';
import type { RecoveryPlanRequest } from './RecoveryPlanRequest';
import type { RelativeCartesianMotionPlanRequest } from './RelativeCartesianMotionPlanRequest';
import type { RelativeJointMotionPlanRequest } from './RelativeJointMotionPlanRequest';

const log = makeNamespacedLog('WebSocketMotionPlanner');

type WebSocketEvents = {
  data: any;
  end: void;
  error: Error;
};

type SimpleIsometry = {
  x: number;
  y: number;
  z: number;
  w: number;
  i: number;
  j: number;
  k: number;
};

type Point3 = {
  x: number;
  y: number;
  z: number;
};

/**
 * Access a motion planner implemented with WebSockets in the
 * [modelone_ros repository](https://github.com/standardbots/modelone_ros).
 *
 * [server code](https://github.com/standardbots/modelone_ros/blob/master/modelone_moveit_interface/src/server.cpp)
 */
export default class WebSocketMotionPlanner implements MotionPlannerInterface {
  private events = new EventEmitter<{
    motionPlanResponse(
      request:
        | MotionPlanRequest
        | RelativeCartesianMotionPlanRequest
        | RelativeJointMotionPlanRequest
        | RecoveryPlanRequest,
      response: MotionPlanResponse,
    ): void;
  }>();

  private motionPlanCache: ReadThroughCache<
    MotionPlanRequest,
    MotionPlanResponse
  > | null = null;

  public isHealthy = false;

  public constructor(
    private apiEndpoint: string,
    motionPlanCacheBackend: CacheBackend<MotionPlanResponse> | null = null,
  ) {
    /*
     * Nice but excessive logging for all waypoints for debugging motion plans
     * when they come back.
    this.events.on('motionPlanResponse', (response) => {
      response.on('completedMotionPlan', (motionPlan) => {
        for (let ii = 0; ii < motionPlan.length; ii += 1) {
          const moment = motionPlan[ii];
          const momentString = moment.joints
            .map((joint) => joint.p.toFixed(3))
            .join(', ');

          log.debug(`uncategorized`,
            `${(moment.timestamp * 1000).toFixed(0)}ms: ${momentString}`,
          );
        }
      });
    });
  */

    if (motionPlanCacheBackend) {
      this.motionPlanCache = new ReadThroughCache<
        MotionPlanRequest,
        MotionPlanResponse
      >({
        getValue: (request: MotionPlanRequest) => this.getMotionPlan(request),
        getCacheKey: (request: MotionPlanRequest) =>
          getMotionPlanRequestCacheKey(request),
        cacheBackend: motionPlanCacheBackend,
      });
    }
  }

  private isRunning = false;

  public async checkHealth(): Promise<boolean> {
    // We can't log the whole WebSocket of WebSocket.ErrorEvent.target because
    // of circular dependency issues in JSON. This function will only log
    // certain fields to avoid the problem.
    const stringifyErrorEvent = (event: WebSocket.ErrorEvent): string => {
      return JSON.stringify(
        {
          error: event.error?.toString() ?? 'Unknown error',
          message: event.message,
          type: event.type,
          target: {
            url: event.target.url,
            readyState: event.target.readyState,
            protocol: event.target.protocol,
            binaryType: event.target.binaryType,
          },
        },
        null,
        2,
      );
    };

    return new Promise((resolve) => {
      try {
        const ws = new WebSocket(this.apiEndpoint);

        ws.onopen = () => {
          ws.close();
          resolve(true);
        };

        ws.onerror = (error) => {
          log.warn(`uncategorized`, 'Not healthy', stringifyErrorEvent(error));
          resolve(false);
        };

        const timeout = setTimeout(() => {
          log.warn(`uncategorized`, 'Connection timeout');
          ws.close();
          resolve(false);
        }, 5000);

        ws.onopen = () => {
          clearTimeout(timeout);
          ws.close();
          resolve(true);
        };

        ws.onerror = (error) => {
          clearTimeout(timeout);
          log.warn(`uncategorized`, 'Not healthy', stringifyErrorEvent(error));
          resolve(false);
        };
      } catch (error) {
        log.warn(`uncategorized`, 'Not healthy', stringifyErrorEvent(error));
        resolve(false);
      }
    });
  }

  /**
   * Monitors health. Returns a function to stop monitoring
   */
  public async monitorHealth() {
    this.isRunning = true;

    while (this.isRunning) {
      this.isHealthy = await this.checkHealth();

      await wait(1000);
    }
  }

  public destroy() {
    this.isRunning = false;
  }

  /**
   * Plan a motion between any number of points.
   *
   * Posts a request to the Motion Planning server and parses the results.
   *
   * The implementation is based on the test.js file used in that repo.
   *
   * TODO: Does not currently support the new grasp APIs
   * https://linear.app/standardbots/issue/SW-131
   */
  public planMotion(request: MotionPlanRequest): MotionPlanResponse {
    const [plan, isCached] = (() => {
      if (this.motionPlanCache) {
        const wasCached = this.motionPlanCache.has(request);
        const response = this.motionPlanCache.get(request);

        log.info(`motionPlanCache.stats`, 'planMotion cache stats', {
          hitCount: this.motionPlanCache.hitCount,
          getCount: this.motionPlanCache.getCount,
          hitRate: (
            this.motionPlanCache.hitCount / this.motionPlanCache.getCount
          ).toPrecision(4),
          cacheSize: this.motionPlanCache.size(),
        });

        return [response, wasCached] as const;
      }

      return [this.getMotionPlan(request), false] as const;
    })();

    log.info(`motionPlan.request`, 'Motion Planning Request', {
      request,
      isCached,
      cachingEnabled: !!this.motionPlanCache,
    });

    return plan;
  }

  public clearMotionPlanCache(): void {
    if (this.motionPlanCache) this.motionPlanCache.reset();
  }

  private getMotionPlan(request: MotionPlanRequest): MotionPlanResponse {
    const error = new Error();
    const response = new MotionPlanResponse();
    this.events.emit('motionPlanResponse', request, response);
    const startTime = new Date();
    const messageStream = this.request('plan_path', request);

    log.debug(`getMotionPlan`, `Using ${this.apiEndpoint} as a planner`);
    let lastMilestone = new Date();

    messageStream.on('data', (data: any) => {
      switch (data.kind) {
        case 'planning': {
          const time = new Date();

          log.debug(`getMotionPlan.data`, 'Plan request acknowledged', {
            startTime,
            elapsedTimeMs: time.getTime() - startTime.getTime(),
          });

          lastMilestone = time;

          response.emit('acknowledged');
          break;
        }

        case 'success': {
          // this is sent when the plan is done but the server hasn't
          // started encoding/sending the waypoints yet
          const time = new Date();

          log.debug(
            `getMotionPlan.success`,
            `Plan motion success: ${time.getTime() - startTime.getTime()}ms`,
            {
              startTime,
              elapsedTimeMs: time.getTime() - startTime.getTime(),
              elapsedSinceLastMilestoneMs:
                time.getTime() - lastMilestone.getTime(),
            },
          );

          break;
        }

        case 'error': {
          log.error(`getMotionPlan.error`, 'Motion planning unsuccessful', {
            reason: data.reason,
            request,
          });

          lastMilestone = new Date();

          error.message = `Motion planning unsuccessful: ${data.reason}.`;

          response.emit('error', error);
          break;
        }

        case 'waypoint': {
          const armParse = MotionPlanArmStep.safeParse(data);

          if (armParse.success) {
            response.emit('waypoint', armParse.data);
          } else {
            log.error(
              `waypoint.validation`,
              'Received waypoint with invalid data:',
              data,
            );
          }

          break;
        }

        case 'gripper': {
          const gripperParse = MotionPlanGripperStep.safeParse(data);

          if (gripperParse.success) {
            response.emit('gripper', gripperParse.data);
          } else {
            log.error(
              `gripper.validation`,
              'Received gripper waypoint with invalid data:',
              data,
            );
          }

          break;
        }
        default: {
          log.error(
            `unkownKind`,
            `Received data with kind "${data.kind}" from plan motion`,
            data,
          );

          break;
        }
      }
    });

    messageStream.on('end', () => {
      const time = new Date();

      log.debug(
        `getMotionPlan.complete`,
        `Plan motion completed: ${time.getTime() - startTime.getTime()}ms`,
      );

      response.emit('done');
      messageStream.removeAllListeners();
    });

    messageStream.on('error', (error1) => {
      log.error(`getMotionPlan.error`, 'Motion planning unsuccessful', error1);
      response.emit('error', error1);
    });

    return response;
  }

  public planRecoveryMotion(request: RecoveryPlanRequest): MotionPlanResponse {
    const response = new MotionPlanResponse();
    this.events.emit('motionPlanResponse', request, response);

    log.debug(`planRecoveryMotion.start`, `Planning Recovery Motion`, {
      api: this.apiEndpoint,
      request,
    });

    const startTime = new Date();
    const messageStream = this.request('plan_recovery_path', request);
    const error = new Error();

    messageStream.on('data', (data: any) => {
      switch (data.kind) {
        case 'planning': {
          const time = new Date();

          log.debug(
            `planRecoveryMotion.planning`,
            `Recovery motion: ${time.getTime() - startTime.getTime()}ms`,
          );

          response.emit('acknowledged');
          break;
        }

        case 'success': {
          // this is sent when the plan is done but the server hasn't
          // started encoding/sending the waypoints yet
          break;
        }

        case 'error': {
          error.message = `Motion planning unsuccessful: ${data.reason}`;
          response.emit('error', error);
          break;
        }

        case 'waypoint': {
          const armParse = MotionPlanArmStep.safeParse(data);

          if (armParse.success) {
            response.emit('waypoint', armParse.data);
          } else {
            log.error(
              `planRecoveryMotion.waypoint.validate`,
              'Received waypoint with invalid data:',
              data,
            );
          }

          break;
        }
        default: {
          log.error(
            `planRecoveryMotion.unknownKind`,
            `Received data with kind "${data.kind}" from recovery motion`,
            data,
          );

          break;
        }
      }
    });

    messageStream.on('end', () => {
      response.emit('done');
      messageStream.removeAllListeners();
    });

    messageStream.on('error', (error1) => {
      response.emit('error', error1);
    });

    return response;
  }

  public planRelativeCartesianMotion(
    request: RelativeCartesianMotionPlanRequest,
  ): MotionPlanResponse {
    const response = new MotionPlanResponse();
    this.events.emit('motionPlanResponse', request, response);
    const startTime = new Date();

    const messageStream = this.request(
      'plan_relative_cartesian_motion',
      request,
    );

    const error = new Error();
    log.debug(`uncategorized`, `Using ${this.apiEndpoint} as a planner`);
    let waypoints = 0;

    messageStream.on('data', (data: any) => {
      switch (data.kind) {
        case 'planning': {
          const time = new Date();

          log.debug(
            `uncategorized`,
            `Plan relative cartesian motion acknowledged: ${
              time.getTime() - startTime.getTime()
            }ms`,
          );

          response.emit('acknowledged');
          break;
        }

        case 'success': {
          // this is sent when the plan is done but the server hasn't
          // started encoding/sending the waypoints yet
          const time = new Date();

          log.debug(
            `uncategorized`,
            `Plan relative cartesian success: ${
              time.getTime() - startTime.getTime()
            }ms`,
          );

          break;
        }

        case 'error': {
          error.message = `Motion planning unsuccessful: ${data.reason}`;
          response.emit('error', error);
          break;
        }

        case 'waypoint': {
          waypoints += 1;
          const armParse = MotionPlanArmStep.safeParse(data);

          if (armParse.success) {
            response.emit('waypoint', armParse.data);
          } else {
            log.warn(
              `uncategorized`,
              'Received waypoint with invalid data:',
              data,
            );
          }

          break;
        }
        default: {
          log.warn(
            `uncategorized`,
            `Received data with kind "${data.kind}" from plan relative cartesian motion`,
            data,
          );

          break;
        }
      }
    });

    messageStream.on('end', () => {
      const time = new Date();

      log.debug(
        `uncategorized`,
        `Plan relative cartesian motion completed: ${
          time.getTime() - startTime.getTime()
        }ms with ${waypoints} waypoints`,
      );

      response.emit('done');
      messageStream.removeAllListeners();
    });

    messageStream.on('error', (error1) => {
      response.emit('error', error1);
    });

    return response;
  }

  public planRelativeJointMotion(
    request: RelativeJointMotionPlanRequest,
  ): MotionPlanResponse {
    const response = new MotionPlanResponse();
    this.events.emit('motionPlanResponse', request, response);
    const startTime = new Date();
    const messageStream = this.request('plan_relative_joint_motion', request);

    const error = new Error();

    log.debug(`planRelativeJointMotion.start`, 'Using as a planner', {
      apiEndpoint: this.apiEndpoint,
    });

    let waypoints = 0;

    messageStream.on('data', (data: any) => {
      switch (data.kind) {
        case 'planning': {
          const time = new Date();

          log.debug(
            `planRelativeJointMotion.planning`,
            `Plan relative joint motion acknowledged: ${
              time.getTime() - startTime.getTime()
            }ms`,
          );

          response.emit('acknowledged');
          break;
        }

        case 'success': {
          // this is sent when the plan is done but the server hasn't
          // started encoding/sending the waypoints yet
          const time = new Date();

          log.debug(
            `planRelativeJointMotion.success`,
            `Plan relative joint motion success: ${
              time.getTime() - startTime.getTime()
            }ms`,
          );

          break;
        }

        case 'error': {
          error.message = `Motion planning unsuccessful: ${data.reason}`;
          response.emit('error', error);
          break;
        }

        case 'waypoint': {
          waypoints += 1;
          const armParse = MotionPlanArmStep.safeParse(data);

          if (armParse.success) {
            response.emit('waypoint', armParse.data);
          } else {
            log.warn(
              `planRelativeJointMotion.waypoint`,
              'Received waypoint with invalid data:',
              data,
            );
          }

          break;
        }
        default: {
          log.warn(
            `planRelativeJointMotion.unknownKind`,
            `Received data with kind "${data.kind}" from plan relative joint motion`,
            data,
          );

          break;
        }
      }
    });

    messageStream.on('end', () => {
      const time = new Date();

      log.debug(
        `planRelativeJointMotion.end`,
        `Plan relative joint motion completed: ${
          time.getTime() - startTime.getTime()
        }ms with ${waypoints} waypoints`,
      );

      response.emit('done');
      messageStream.removeAllListeners();
    });

    messageStream.on('error', (error1) => {
      response.emit('error', error1);
    });

    return response;
  }

  private forwardKinematicsCache = new LRUCache<
    string,
    Promise<ForwardKinematicsResponse>
  >({ max: 50 });

  public async forwardKinematics(
    request: ForwardKinematicsRequest,
  ): Promise<ForwardKinematicsResponse> {
    // key is just a unique stringified version of the request
    // with rounded angles
    const key = `
      ${request.jointAngles.map((angle) => `${angle.toFixed(4)}`).join('|')}
      ${request.checkValidity}
      ${request.gripperOpenness.toFixed(2)}
    `;

    const cachedKinematics = this.forwardKinematicsCache.get(key);

    if (cachedKinematics) {
      return cachedKinematics;
    }

    const error = new Error();

    const forwardKinematicsPromise = new Promise<ForwardKinematicsResponse>(
      (resolve, reject) => {
        log.debug(`uncategorized`, `Using ${this.apiEndpoint} as a planner`);
        const messageStream = this.request('forward_kinematics', request);

        messageStream.on('data', (data: any) => {
          if (!data || !data.kind) {
            log.error(`uncategorized`, 'Received non-kinded data', data);
            error.message = 'Received non-kinded data';
            reject(error);

            return;
          }

          switch (data.kind) {
            case 'error': {
              error.message = data.reason;
              reject(error);
              break;
            }

            case 'solution': {
              try {
                // If the request does not specify to check validity, the motion planner
                // doesn't return isColliding, so we can default it to false here.
                const isColliding = request.checkValidity
                  ? data.isColliding
                  : false;

                // validate the pose and collisions
                const response = ForwardKinematicsResponse.parse({
                  pose: data.pose,
                  collisions: data.collisions,
                  isColliding,
                });

                resolve(response);
              } catch (validationError) {
                log.error(
                  `uncategorized`,
                  'Forward kinematics gave back invalid response',
                  { validationError, request },
                );

                error.message =
                  'Forward kinematics returned invalid response. See console for more information.';

                reject(error);
              }

              break;
            }
            default: {
              log.warn(
                `uncategorized`,
                `Received data with kind "${data.kind}" from forward kinematics`,
                data,
              );

              break;
            }
          }
        });

        messageStream.once('error', reject);
      },
    )
      // if the response turns out to be an error, remove it from the cache
      .catch((error1) => {
        this.forwardKinematicsCache.delete(key);
        throw error1;
      });

    this.forwardKinematicsCache.set(key, forwardKinematicsPromise);

    return forwardKinematicsPromise;
  }

  public inverseKinematics(
    request: InverseKinematicsRequest,
  ): Promise<Array<InverseKinematicsSolution>> {
    const error = new Error();

    return new Promise<Array<InverseKinematicsSolution>>((resolve, reject) => {
      const messageStream = this.request('inverse_kinematics', request);

      const solutions: Array<InverseKinematicsSolution> = [];

      messageStream.on('data', (data: any) => {
        switch (data.kind) {
          case 'error': {
            error.message = data.reason;
            reject(error);
            break;
          }

          case 'solution': {
            solutions.push(data);
            break;
          }

          default: {
            log.warn(
              `uncategorized`,
              `Received data with kind "${data.kind}" from inverse kinematics`,
              data,
            );

            break;
          }
        }
      });

      messageStream.once('end', () => {
        resolve(solutions);
      });

      messageStream.once('error', reject);
    });
  }

  public async removeEndEffector() {
    // Overwrite existing collision boxes with blank collision object
    return this.attachEndEffector({ name: '' });
  }

  public async attachEndEffector(
    kinematics: DeviceKinematics<any>,
  ): Promise<void> {
    let error: Error | null = new Error();

    const messageStream = this.request('attach_end_effector', {
      name: kinematics.name,
      collisionBoxes: kinematics.getEndEffectorCollisionBoxes?.() ?? [],
      weight: kinematics.getEndEffectorMassKG?.() ?? 0,
    });

    await messageStream.next('data', (data: any) => {
      switch (data.kind) {
        case 'success': {
          log.info(`uncategorized`, 'removed obstacle', data.obstacleName);

          error = null;

          return true;
        }

        case 'error': {
          error!.message = data.reason;

          return true;
        }
        default:
          return false;
      }
    });

    if (error) {
      log.warn(`uncategorized`, error.message);
    }
  }

  public clearObstacles(): Promise<void> {
    const error = new Error();

    return new Promise((resolve, reject) => {
      const messageStream = this.request('clear_obstacles', {});

      messageStream.on('data', (data: any) => {
        switch (data.kind) {
          case 'success': {
            log.info(`uncategorized`, 'removed obstacle', data.obstacleName);
            resolve();
            break;
          }

          case 'error': {
            error.message = data.reason;
            reject(error);
            break;
          }

          default:
            break;
        }
      });
    });
  }

  public addObstacle(
    obstacle:
      | {
          obstacleKind: 'surface';
          surfacePoints: [Point3, Point3, Point3, Point3];
          obstacleOrigin: SimpleIsometry;
          obstacleName: string;
        }
      | {
          obstacleKind: 'pointcloud';
          cloudPoints: Array<Point3>;
          cloudResolution: number;
          obstacleOrigin: SimpleIsometry;
          obstacleName: string;
        },
  ): Promise<void> {
    const error = new Error();

    return new Promise((resolve, reject) => {
      const messageStream = this.request('add_obstacle', obstacle);

      messageStream.on('data', (data: any) => {
        switch (data.kind) {
          case 'success': {
            log.info(`uncategorized`, 'removed obstacle', data.obstacleName);
            resolve();
            break;
          }

          case 'error': {
            error.message = data.reason;
            reject(error);
            break;
          }

          default:
            break;
        }
      });
    });
  }

  public async moveObstacle(
    obstacleName: string,
    obstacleOrigin: SimpleIsometry,
  ): Promise<void> {
    const error = new Error();

    return new Promise<void>((resolve, reject) => {
      const messageStream = this.request('move_obstacle', {
        obstacleOrigin,
        obstacleName,
      });

      messageStream.on('data', (data: any) => {
        switch (data.kind) {
          case 'success': {
            log.info(`uncategorized`, 'removed obstacle', data.obstacleName);
            resolve();
            break;
          }

          case 'error': {
            error.message = data.kind;
            reject(error);
            break;
          }

          default:
            break;
        }
      });
    });
  }

  public async removeObstacle(obstacleName: string): Promise<void> {
    const error = new Error();

    return new Promise((resolve, reject) => {
      const messageStream = this.request('remove_obstacle', {
        obstacleName,
      });

      messageStream.on('data', (data: any) => {
        switch (data.kind) {
          case 'success': {
            log.info(`uncategorized`, 'removed obstacle', data.obstacleName);
            resolve();
            break;
          }

          case 'error': {
            error.message = data.kind;
            reject(error);
            break;
          }

          default:
            break;
        }
      });
    });
  }

  public onMotionPlan(
    cb: (request: AnyMotionPlanRequest, response: MotionPlanResponse) => void,
  ): () => void {
    return this.events.on('motionPlanResponse', cb);
  }

  /**
   * Common logic wrapping requests over WebSockets
   */
  private request(
    endpoint: string,
    request: any,
  ): EventEmitter<WebSocketEvents> {
    // error isn't set in browser so emit a generic error
    const defaultError = new Error('Failed to generate motion plan');

    log.debug(`uncategorized`, 'Requesting:', request);
    const ws = new WebSocket(`${this.apiEndpoint}/${endpoint}`);
    ws.binaryType = 'arraybuffer';
    const emitter = new EventEmitter<WebSocketEvents>();

    ws.addEventListener('open', () => {
      ws.send(JSON.stringify(request));
    });

    ws.addEventListener('message', ({ data }) => {
      const message = JSON.parse(data);
      emitter.emit('data', message);
    });

    ws.addEventListener('close', () => {
      emitter.emit('end');
    });

    ws.addEventListener('error', ({ error = defaultError }) => {
      emitter.emit('error', error);
    });

    return emitter;
  }
}
