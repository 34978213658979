import * as zod from 'zod';

export default zod.object({
  // Used to communicate that the confirmation button in question has been pressed.
  // The step sets this to false when this starts up and then waits for it to turn true
  // from an external event.
  confirmed: zod.boolean(),
  choice: zod.string().optional(),
  freeformInput: zod.string().optional(),
});
