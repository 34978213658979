/**
 * Control System Message: one message from the robotics control system
 */
import * as zod from 'zod';

import {
  ArmJointTorques,
  ArmJointVelocities,
  JointNumber,
  NullableArmJointPositions,
} from '@sb/motion-planning';
import { sixteen, two } from '@sb/utilities';

export const CollisionEvent = zod.object({
  kind: zod.literal('collision'),
  collision: zod.object({
    joint: JointNumber,
    disturbanceDetected: zod.number(),
    disturbanceThreshold: zod.number(),
    expectedTorque: zod.number(),
    amperageTorque: zod.number(),
    windupTorque: zod.number(),
    disturbances: ArmJointTorques,
    positions: NullableArmJointPositions,
    velocities: ArmJointVelocities,
  }),
});

export type CollisionEvent = zod.infer<typeof CollisionEvent>;

export const EStopTriggeredEvent = zod.object({
  kind: zod.literal('eStopTriggered'),
  eStopTriggered: zod.object({
    source: zod.string(),
  }),
});

export type EStopTriggeredEvent = zod.infer<typeof EStopTriggeredEvent>;

export const PowerBoardUnhealthyEvent = zod.object({
  kind: zod.literal('powerBoardUnhealthy'),
  powerBoardUnhealthy: zod.object({
    reason: zod.string(),
  }),
});

export type PowerBoardUnhealthyEvent = zod.infer<
  typeof PowerBoardUnhealthyEvent
>;

/**
 * Emitted when the state of a button changes
 */
export const ButtonStateChangeEvent = zod.object({
  kind: zod.literal('buttonStateChangeEvent'),
  buttonStateChangeEvent: zod.object({
    buttonLocation: zod.enum(['controlBox', 'wrist']),
    buttonIndex: zod.number(),
    newButtonState: zod.number(),
  }),
});

export type ButtonStateChangeEvent = zod.infer<typeof ButtonStateChangeEvent>;

// not yet appropriately emitted by followMotionPlan
export const OutOfLimitsEvent = zod.object({
  kind: zod.literal('outOfLimits'),
  outOfLimits: zod.object({
    joint: JointNumber,
    position: zod.number(),
    lowLimit: zod.number(),
    highLimit: zod.number(),
  }),
});

export type OutOfLimitsEvent = zod.infer<typeof OutOfLimitsEvent>;

export const IOState = zod.object({
  eStops: zod.tuple(two(zod.boolean())),
  robotToExternalIO: zod.tuple(sixteen(zod.boolean())),
  externalToRobotIO: zod.tuple(sixteen(zod.boolean())),
  safeguardState: zod.union([
    zod.literal('fullSpeed'),
    zod.literal('slowSpeed'),
    zod.literal('eStop'),
  ]),
});

export type IOState = zod.infer<typeof IOState>;

export const IOStateChanged = zod.object({
  kind: zod.literal('ioStateChanged'),
  ioStateChanged: zod.object({
    oldState: IOState.nullable(),
    newState: IOState.nullable(),
    changes: zod.array(zod.string()),
  }),
});

export type IOStateChanged = zod.infer<typeof IOStateChanged>;

export const BotmanHeartbeatLostEvent = zod.object({
  kind: zod.literal('botmanHeartbeatLost'),
  botmanHeartbeatLost: zod.object({}),
});

export type BotmanHeartbeatLostEvent = zod.infer<
  typeof BotmanHeartbeatLostEvent
>;

export const BotmanHeartbeatRegainedEvent = zod.object({
  kind: zod.literal('botmanHeartbeatRegained'),
  botmanHeartbeatRegained: zod.object({}),
});

export type BotmanHeartbeatRegainedEvent = zod.infer<
  typeof BotmanHeartbeatRegainedEvent
>;

// catchall for as-yet uncategorized control system events
export const GeneralControlSystemEvent = zod.object({
  kind: zod.literal('controlSystemEvent'),
  controlSystemEvent: zod.object({
    message: zod.string(),
    level: zod.enum(['INFO', 'WARNING', 'VIOLATION', 'ERROR', 'CRITICAL']),
    positions: NullableArmJointPositions,
    velocities: ArmJointVelocities,
  }),
});

export type GeneralControlSystemEvent = zod.infer<
  typeof GeneralControlSystemEvent
>;

export const ControlSystemEvent = zod
  .union([
    CollisionEvent,
    EStopTriggeredEvent,
    ButtonStateChangeEvent,
    GeneralControlSystemEvent,
    OutOfLimitsEvent,
    IOStateChanged,
    BotmanHeartbeatLostEvent,
    BotmanHeartbeatRegainedEvent,
    PowerBoardUnhealthyEvent,
  ])
  .and(
    zod.object({
      // ISO-8601
      time: zod.string(),
    }),
  );

export type ControlSystemEvent = zod.infer<typeof ControlSystemEvent>;
