import cx from 'classnames';
import type { HTMLAttributes } from 'react';
import { cloneElement, forwardRef, useContext, useMemo } from 'react';

import { CloseIcon } from '@sb/ui/icons';

import IconButton from '../IconButton';
import type { TypographyComponent } from '../Typography';
import Typography from '../Typography';

import { ModalContext } from './shared';

import styles from './ModalHeader.module.css';

interface ModalHeaderProps extends Omit<HTMLAttributes<HTMLElement>, 'title'> {
  hideCloseIcon?: boolean;
  isBorderHidden?: boolean;
  startIcon?: React.ReactElement;
  title: React.ReactNode;
  titleComponent?: TypographyComponent;
  'data-testid'?: string;
}

const ModalHeader = forwardRef<HTMLElement, ModalHeaderProps>((props, ref) => {
  const { onClose } = useContext(ModalContext);

  const {
    children,
    className,
    titleComponent = 'h5',
    hideCloseIcon,
    isBorderHidden,
    startIcon,
    title,
    'data-testid': testID,
    ...other
  } = props;

  const StartIconComponent = useMemo(() => {
    return (
      startIcon &&
      cloneElement(startIcon, {
        variant: 'black',
        size: 'small',
        className: styles.startIcon,
      })
    );
  }, [startIcon]);

  return (
    <header
      data-testid={testID && `${testID}--header`}
      ref={ref}
      className={cx(
        styles.modalHeader,
        { [styles.noBorder]: isBorderHidden },
        className,
        'tw-surface-secondary',
        'tw-shadow-80',
        'tw-rounded-18',
      )}
      {...other}
    >
      <div className={styles.title}>
        {StartIconComponent}

        <Typography
          component={titleComponent}
          hasNoWrap
          data-testid={testID && `${testID}--label`}
        >
          {title}
        </Typography>
      </div>

      {children}

      {!hideCloseIcon && onClose && (
        <IconButton
          onClick={onClose}
          data-testid={testID && `${testID}--close-button`}
        >
          <CloseIcon className={styles.closeIcon} />
        </IconButton>
      )}
    </header>
  );
});

export default ModalHeader;
