/**
 * Globals object specification constructed as a zod schema for static and
 * dynamic validation.
 */
import * as zod from 'zod';

import { Space } from './types';

export const Globals = zod.object({
  globalSpace: zod.array(Space.Item),
});

export type Globals = zod.infer<typeof Globals>;
