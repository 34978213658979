import type { Space } from '@sb/routine-runner';

import { getGlobalSpaceService } from './utils/getGlobalSpaceService';

export async function createGlobalSpace(
  globalSpace: Space.Item,
): Promise<string> {
  const response = await getGlobalSpaceService().create({
    spaceItem: globalSpace,
  });

  return response.id;
}
