import { clamp } from 'lodash';

import type { RobotData } from '@sb/feathers-types';
import type { EquipmentItem } from '@sb/integrations/types';
import type { Robot } from '@sb/remote-control/types';
import type {
  ExternalControlSettings,
  SafeguardRule,
} from '@sb/routine-runner';

import { getIOPorts } from './getIOPorts';

export function convertRobotResponse(
  robotData: RobotData,
  safeguardRules: SafeguardRule[],
  externalControlSettings: ExternalControlSettings | undefined,
  equipment: EquipmentItem[],
): Robot.ConvertedResponse {
  const { ioInputs, ioOutputs, ioRelays, ioFlangeInputs, ioFlangeOutputs } =
    getIOPorts(robotData, safeguardRules, externalControlSettings, equipment);

  return {
    id: robotData.robotID,
    ioInputs,
    ioOutputs,
    ioRelays,
    ioFlangeInputs,
    ioFlangeOutputs,
    latestRoutineID: robotData.latestRoutineID,
    latestRoutineLoadedAt: robotData.latestRoutineLoadedAt
      ? new Date(robotData.latestRoutineLoadedAt)
      : undefined,
    name: robotData.name,
    routineRunnerState: {
      kind: robotData.routineRunnerStateKind ?? 'Constructing',
      isPaused: robotData.routineIsPaused,
    },
    speedRestrictionPercentage: clamp(
      robotData.speedRestrictionPercentage,
      0,
      1,
    ),
    testRunCompletedAt: robotData.testRunCompletedAt
      ? new Date(robotData.testRunCompletedAt)
      : undefined,
    lastKnownLocalIP: robotData.lastKnownLocalIP,
  };
}
