import * as zod from 'zod';

import { IntegrationActionControlBoxIOInterface } from './IntegrationActionControlBoxIOInterface';
import { IntegrationActionHaasNGCInterface } from './IntegrationActionHaasNGCInterface';
import { IntegrationActionModbusTCPInterface } from './IntegrationActionModbusTCPInterface';

export const IntegrationActionInterface = zod.discriminatedUnion('kind', [
  IntegrationActionControlBoxIOInterface,
  IntegrationActionModbusTCPInterface,
  IntegrationActionHaasNGCInterface,
]);

export type IntegrationActionInterface = zod.infer<
  typeof IntegrationActionInterface
>;
