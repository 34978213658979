export const homescope = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.359802 12.2318L12.3598 2.23175H13.6402L25.6402 12.2318L24.3598 13.7682L22.238 12H20.5V10.5517L13 4.30168L5.49999 10.5517V23H14.9596C15.2404 23.734 15.6411 24.4087 16.1379 25H4.49999L3.49999 24V12.2183L1.64017 13.7682L0.359802 12.2318ZM20.5 17.6707V19H22.5V17.6707C23.3524 17.972 24.028 18.6476 24.3293 19.5H23V21.5H24.3293C24.028 22.3524 23.3524 23.028 22.5 23.3293V22H20.5V23.3293C19.6476 23.028 18.972 22.3524 18.6707 21.5H20V19.5H18.6707C18.972 18.6476 19.6476 17.972 20.5 17.6707ZM20.5 25.4C18.5409 25.0023 16.9977 23.4591 16.6 21.5H15V19.5H16.6C16.9977 17.5409 18.5409 15.9977 20.5 15.6V14H22.5V15.6C24.4591 15.9977 26.0023 17.5409 26.4 19.5H28V21.5H26.4C26.0023 23.4591 24.4591 25.0023 22.5 25.4V27H20.5V25.4Z"
      fill="#0F0E0D"
      fillOpacity="0.9"
    />
  </svg>
);
