import * as zod from 'zod';

const SetRegisterValue = zod
  .function()
  .args(
    zod.object({
      registerAddress: zod.number(),
      registerValue: zod.number(),
    }),
  )
  .returns(zod.void());

const SetMultipleRegisterValues = zod
  .function()
  .args(
    zod.object({
      registerAddress: zod.number(),
      registerValues: zod.number().array(),
    }),
  )
  .returns(zod.void());

const Wait = zod.function().args(zod.number()).returns(zod.promise(zod.void()));

const SetCoilValue = zod
  .function()
  .args(
    zod.object({
      coilAddress: zod.number(),
      coilValue: zod.boolean(),
    }),
  )
  .returns(zod.void());

const SetMultipleCoilValues = zod
  .function()
  .args(
    zod.object({
      coilAddress: zod.number(),
      coilValues: zod.boolean().array(),
    }),
  )
  .returns(zod.void());

export const ModbusTCPConnection = zod.object({
  isConnected: zod.boolean(),
  setRegisterValue: SetRegisterValue.nullable(),
  setMultipleRegisterValues: SetMultipleRegisterValues.nullable(),
  wait: Wait.nullable(),
  setCoilValue: SetCoilValue.nullable(),
  setMultipleCoilValues: SetMultipleCoilValues.nullable(),
});

export type ModbusTCPConnection = zod.infer<typeof ModbusTCPConnection>;

export const CNCMachineState = zod.object({
  kind: zod.literal('CNCMachine'),
  isConnected: zod.boolean(),
  modbusTCPConnection: ModbusTCPConnection,
  isCellSafeSupported: zod.boolean(),
  isCellSafe: zod.boolean(),
  runningProgram: zod.string().nullable(),
  status: zod.enum(['idle', 'running', 'fault', 'unknown']),
});

export type CNCMachineState = zod.infer<typeof CNCMachineState>;
