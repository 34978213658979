import { useEffect, useState } from 'react';

import type { Robot } from '@sb/remote-control/types';
import { onGetRobots } from '@sbrc/services';

const useRobotList = (routineID?: string | null) => {
  const [robots, setRobots] = useState<Robot.ConvertedResponse[]>([]);

  useEffect(() => {
    const unsubscribe = onGetRobots({ routineID }, setRobots);

    return () => {
      unsubscribe();
    };
  }, [routineID]);

  return robots;
};

export default useRobotList;
