import * as zod from 'zod';

import { CartesianPose, CartesianPosition } from '@sb/geometry';
import {
  CameraIntegration,
  CameraIntrinsics,
} from '@sb/integrations/types/cameraTypes';
import { three } from '@sb/utilities';

import { Space } from '../types';

import {
  Blob2D,
  Blob2DParams,
  LocateMethod,
  Shape2DParams,
  TemplateImage,
} from './LocateTypes';
import {
  CalculateIntrinsicsResult,
  ChessboardCornersResult,
  CalibrationEntry,
  ChessboardParameters,
  ClassifyClass,
  ClassifyResult,
  RegionOfInterest,
} from './VisionInterface';

/* classify */

const RunClassifyArgs = zod.object({
  method: zod.literal('classify'),
  camera: CameraIntegration,
  regionOfInterest: RegionOfInterest,
  classes: ClassifyClass.array(),
});

const RunClassifyResult = zod.object({
  method: zod.literal('classify'),
  results: ClassifyResult.array(),
});

/* detect 2d blobs */

const RunDetect2DBlobsArgs = zod.object({
  method: zod.literal('detect2DBlobs'),
  camera: CameraIntegration,
  regionOfInterest: RegionOfInterest,
  params: Blob2DParams,
});

export type RunDetect2DBlobsArgs = zod.infer<typeof RunDetect2DBlobsArgs>;

const RunDetect2DBlobsResult = zod.object({
  method: zod.literal('detect2DBlobs'),
  results: Blob2D.array(),
});

/* detect 2d shapes */

const RunDetect2DShapesArgs = zod.object({
  method: zod.literal('detect2DShapes'),
  camera: CameraIntegration,
  regionOfInterest: RegionOfInterest,
  params: Shape2DParams,
  templateImage: TemplateImage,
});

export type RunDetect2DShapesArgs = zod.infer<typeof RunDetect2DShapesArgs>;

const RunDetect2DShapesResult = zod.object({
  method: zod.literal('detect2DShapes'),
  results: Blob2D.array(),
});

/* get chessboard corners */

const RunGetChessboardCornersArgs = zod.object({
  method: zod.literal('getChessboardCorners'),
  camera: CameraIntegration,
  rows: zod.number(),
  cols: zod.number(),
});

const RunGetChessboardCornersResult = zod.object({
  method: zod.literal('getChessboardCorners'),
  results: ChessboardCornersResult,
});

/* get_camera_chessboard_transform */

const RunGetCameraChessboardTransformArgs = zod.object({
  method: zod.literal('getCameraChessboardTransform'),
  camera: CameraIntegration,
  chessboard: ChessboardParameters,
});

const RunGetCameraChessboardTransformResult = zod.object({
  method: zod.literal('getCameraChessboardTransform'),
  results: CartesianPose,
});

/* locate */

const RunLocateArgs = zod.object({
  method: zod.literal('locate'),
  camera: CameraIntegration,
  regionOfInterest: RegionOfInterest,
  locateMethod: LocateMethod,
  plane: zod.tuple(three(CartesianPosition)),
  resultsLimit: zod.number(),
  transform: CartesianPose.nullable(),
  accuracyCalibration: Space.AccuracyCalibrationEntry.array().nullable(),
  objectHeight: zod.number().default(0),
});

const RunLocateResult = zod.object({
  method: zod.literal('locate'),
  results: zod
    .object({
      blob: Blob2D,
      pose: CartesianPose,
    })
    .array(),
});

export type RunLocateResult = zod.infer<typeof RunLocateResult>;

/* get intrinsics */

const GetIntrinsicsArgs = zod.object({
  method: zod.literal('getIntrinsics'),
});

const GetIntrinsicsResult = zod.object({
  method: zod.literal('getIntrinsics'),
  results: CameraIntrinsics,
});

/* calculate intrinsics */

const RunCalculateIntrinsicsArgs = zod.object({
  method: zod.literal('calculateIntrinsics'),
  calibration: CalibrationEntry.array(),
  chessboard: ChessboardParameters,
});

const RunCalculateIntrinsicsResult = zod.object({
  method: zod.literal('calculateIntrinsics'),
  results: CalculateIntrinsicsResult,
});

/* union */

export const RunVisionMethodArgs = zod.discriminatedUnion('method', [
  RunClassifyArgs,
  RunDetect2DBlobsArgs,
  RunDetect2DShapesArgs,
  RunGetChessboardCornersArgs,
  RunGetCameraChessboardTransformArgs,
  RunLocateArgs,
  GetIntrinsicsArgs,
  RunCalculateIntrinsicsArgs,
]);

export type RunVisionMethodArgs = zod.infer<typeof RunVisionMethodArgs>;

export const RunVisionMethodResult = zod.discriminatedUnion('method', [
  RunClassifyResult,
  RunDetect2DBlobsResult,
  RunDetect2DShapesResult,
  RunGetChessboardCornersResult,
  RunGetCameraChessboardTransformResult,
  RunLocateResult,
  GetIntrinsicsResult,
  RunCalculateIntrinsicsResult,
]);

export type RunVisionMethodResult = zod.infer<typeof RunVisionMethodResult>;
