import * as zod from 'zod';

export enum ModbusFunctionCodeEnum {
  ReadHoldingRegisters = 0x03,
  WriteSingleRegister = 0x06,
  WriteMultipleRegisters = 0x10,
  WriteSingleCoil = 0x05,
  WriteMultipleCoils = 0x0f,
}

export const ModbusFunctionCode = zod.nativeEnum(ModbusFunctionCodeEnum);
