import type { StepSummary, FeatureFlagKey } from '@sb/feathers-types';
import type { DeviceKind, EquipmentItem } from '@sb/integrations/types';
import type { StepConfigurationByKind } from '@sb/remote-control/step';
import type { CodeBlock } from '@sb/remote-control/types';
import type {
  BaseRoutineStepConfiguration,
  RoutineStepConfiguration,
  Space,
  SpeedProfile,
  StepKind,
} from '@sb/routine-runner';

import type { DistanceUnitInfo } from '../misc';
import type { Robot } from '../robot';
import type { Routine } from '../routine';

export namespace Step {
  /**
   * Supported 'args' by the routine runner schema.
   */
  export type Arguments = StepConfigurationByKind['args'] & {};

  export interface Configuration {
    args?: Arguments;
    description?: string;
  }

  /** Add custom fields to the step configuration. */
  export type ConvertedConfiguration = StepConfigurationByKind & {
    id: string;
    description?: string;
    name: string;
    routineID: string;
    stepKind: StepKind;
  };

  /**
   * Contains all fields required for displaying a list of steps
   * in the frontend. It doesn't include robot-specific
   * configuration. This summary is used for storing steps in the `/routines`
   * collection. It's mainly used for the reorder (drag-and-drop) feature.
   */
  export type Summary = StepSummary;

  export type ParentStep = Pick<Summary, 'id' | 'stepKind'> | null;

  /**
   * While "Summary" is the interface used in the database,
   * "ConvertedSummary" contains the format required in the frontend.
   */
  export interface ConvertedSummary extends Omit<Summary, 'steps'> {
    /**
     * When copying a step, this is set to source the config for the new step
     */
    copiedFromStepID?: string;

    name: string;

    /**
     * Some steps require a specific stepKind for the parent step.
     * For example, 'LoopControl' should be inside a 'Loop' step.
     *
     * By keeping track of the parent step when converting a step
     * summary, we can easily validate a step:
     *
     * ```
     * if (step.stepKind === 'LoopControl' && step.parentStep.stepKind !== 'Loop') {
     *   throw new Error('Restart Loop is currently not defined inside a Loop Step');
     * }
     * ```
     */
    parentSteps: ParentStep[];

    /**
     * In some places of the frontend, we need the position of
     * a step in the entire tree. For example:
     *
     * 1. Loop
     *    2. Nested loop step
     *    3. Another nested loop step
     * 4. Another loop step
     *    5. Nested loop step
     *       6. Nested in one more level
     *    7. Another nested step
     */
    stepPosition: number;
    steps: ConvertedSummary[];
  }

  /**
   * Common arguments available for all steps, regardless of their stepKind.
   */
  export interface CommonArguments {
    /**
     * A stepKind can have different kinds of arguments. A "Loop" step, for example,
     * can also have the same arguments as the ones from "If" steps.
     *
     * Using an 'argumentKind' field helps us to improve typing. For example, this works:
     * if (step.argumentKind === 'Loop') console.log(step.times)
     *
     * However, this would give us a build error:
     * if (step.argumentKind === 'Loop') console.log(step.condition)
     *
     * Because 'step.condition' doesn't exist in loops that don't use conditional rules.
     * The same rule applies to conditional steps (i.e. If steps or loop
     * steps using conditional rules):
     *
     * // this works
     * if (step.argumentKind === 'If') console.log(step.condition)
     *
     * // this doesn't work
     * if (step.argumentKind === 'If') console.log(step.times)
     *
     * If we didn't have this 'argumentKind' field, then TypeScript wouldn't
     * be able to correctly identify the arguments because
     * each step can have different index signatures.
     *
     * So, these use cases would give us a build error:
     * console.log(step.times)
     * console.log(step.condition)
     *
     * Because the 'condition' field only exists for conditional steps
     * (i.e. If steps and Loop steps using conditional rules) while
     * the 'times' field only exists in certain loops, for example.
     *
     * Note that this 'argumentKind' field exists in the remote control only
     * and we ARE storing it in the database. However, this field does NOT
     * exist in the routine-runner as the routine-runner has its own dynamic
     * way of generating a schema but we're using a static approach in the
     * remote-control.
     */
    argumentKind: StepKind;
  }

  export interface ActionRequired {
    kind: 'invalidConfiguration' | 'missingConfiguration' | 'missingEquipment';
    message: string;
    fieldId?: string;
  }

  export type Validator = (args: {
    step: ConvertedSummary;
    stepConfiguration: ConvertedConfiguration | undefined;
    routine: Routine.ConvertedResponse;
    equipment: EquipmentItem[];
    globalSpace: Space.Item[];
  }) => void;

  export type ToRoutineRunner = (args: {
    stepConfiguration: ConvertedConfiguration;
    stepData: BaseRoutineStepConfiguration;
    equipment: EquipmentItem[];
    baseSpeedProfile: SpeedProfile;
    codeBlocks?: Omit<CodeBlock, 'created' | 'updated'>[];
  }) => RoutineStepConfiguration;

  export type GetStepDescription = (args: {
    stepConfiguration: ConvertedConfiguration;
    routine?: Routine.ConvertedResponse;
    robot?: Robot.ConvertedResponse;
    includeStepName?: boolean;
    distanceUnitInfo?: DistanceUnitInfo;
    globalSpace?: Space.Item[];
  }) => string | null;

  export enum LibrarySection {
    Basic = 'Basic',
    Control = 'Control',
    InterfaceWithMachines = 'Interface with machines',
    InterfaceWithOperators = 'Interface with operators',
    Vision = 'Vision',
  }

  export interface StepKindInfo {
    name: string;
    description: string;
    // has child steps
    isDecorator?: boolean;
    // steps based on integration. Integration steps only show when integration enabled
    deviceKinds?: readonly DeviceKind[];
    librarySection: LibrarySection;
    librarySort?: string;
    argumentKind: string;
    validator?: Step.Validator;
    toRoutineRunner: Step.ToRoutineRunner;
    getStepDescription?: Step.GetStepDescription;
    featureFlag?: FeatureFlagKey;
  }
}
