import type * as zod from 'zod';

import Step from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

export default class SetEnvironmentVariableStep extends Step<
  Arguments,
  Variables
> {
  public static areSubstepsRequired = false;

  public static Arguments = Arguments;

  public static Variables = Variables;

  public initializeVariableState(): void {
    this.variables = {};
  }

  public async _play(): Promise<void> {
    const value = await this.routineContext.evaluateExpression(
      this.args.newValue,
    );

    if (await super.shouldStopPlaying()) {
      return;
    }

    this.routineContext.setVariableState(this.args.variableID, {
      stepKind: 'EnvironmentVariable',
      value,
      name: this.args.variableName,
    });
  }
}
