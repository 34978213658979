import type { GlobalSpaceData } from '@sb/feathers-types';
import { Space } from '@sb/routine-runner';

export function convertGlobalSpaceResponse(globalSpaceData: GlobalSpaceData): {
  globalSpaceID: string;
  spaceItem: Space.Item;
} {
  const parsedItem = Space.Item.safeParse(globalSpaceData.spaceItem);

  if (parsedItem.success) {
    const spaceItem = parsedItem.data;

    return { globalSpaceID: globalSpaceData.id, spaceItem };
  }

  throw new Error('Failed to parse item');
}
