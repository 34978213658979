import * as zod from 'zod';

export const GlobalSpaceData = zod.object({
  id: zod.string(),
  spaceItem: zod
    .object({
      description: zod.string(),
      id: zod.string(),
      kind: zod.string(),
      name: zod.string(),
    })
    .passthrough(), // additional props are valid
  created: zod.string(),
  updated: zod.string(),
});

export type GlobalSpaceData = zod.infer<typeof GlobalSpaceData>;
