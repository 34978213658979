import * as zod from 'zod';

import { AnyOutputPortID } from './AnyOutputPort';
import { FlangeInputPortID } from './FlangeInputPort';
import { InputPortID } from './InputPort';

const InputPortIDs = zod.union([InputPortID, FlangeInputPortID]);

export const ExternalControlSettings = zod.object({
  isEnabled: zod.boolean().default(false),
  isRobotIOEnabled: zod.boolean().default(false),
  ioPorts: zod
    .object({
      // inputs
      play: InputPortIDs.optional(),
      pause: InputPortIDs.optional(),
      loadDefaultRoutine: InputPortIDs.optional(),
      // outputs
      ready: AnyOutputPortID.optional(),
      running: AnyOutputPortID.optional(),
      fault: AnyOutputPortID.optional(),
      defaultRoutineLoaded: AnyOutputPortID.optional(),
      isInHomePose: AnyOutputPortID.optional(),
    })
    .default({}),
  defaultRoutineID: zod.string().nullable().default(null),
});

export type ExternalControlSettings = zod.infer<typeof ExternalControlSettings>;
