import * as zod from 'zod';

export const IntegrationInterfaceKind = zod.enum([
  'controlBoxIO',
  'modbusTCP',
  'haasNGC',
  'flangeIO',
]);

export type IntegrationInterfaceKind = zod.infer<
  typeof IntegrationInterfaceKind
>;
