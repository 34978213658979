import * as zod from 'zod';

export const CodeBlockData = zod.object({
  id: zod.string(),
  created: zod.string().datetime(),
  updated: zod.string().datetime(),
  name: zod.string(),
  code: zod.string(),
});

export type CodeBlockData = zod.infer<typeof CodeBlockData>;
