export const python = (
  <>
    <rect width="28" height="28" rx="6" fill="#265CF0" />
    <g clipPath="url(#clip0_2010_5754)">
      <path
        d="M23.6523 12.2236C23.3257 10.9562 22.7065 10.0006 21.3875 10.0006H19.6869V11.9447C19.6869 13.4541 18.3636 14.7256 16.8538 14.7256H12.3244C11.086 14.7256 10.0596 15.751 10.0596 16.9527V21.1281C10.0596 22.3176 11.1284 23.0148 12.3244 23.3553C13.7578 23.7613 15.1362 23.8351 16.8538 23.3553C17.9947 23.0353 19.1186 22.3914 19.1186 21.1281V19.4588H14.5933V18.901H21.3875C22.7065 18.901 23.1942 18.0109 23.6523 16.6779C24.1273 15.3039 24.1061 13.9832 23.6523 12.2236ZM17.138 20.5703C17.6087 20.5703 17.9904 20.9435 17.9904 21.4029C17.9904 21.8664 17.6087 22.2396 17.138 22.2396C16.6715 22.2396 16.2855 21.8623 16.2855 21.4029C16.2898 20.9394 16.6715 20.5703 17.138 20.5703ZM12.1165 14.176H16.646C17.9056 14.176 18.9107 13.1711 18.9107 11.9488V7.76933C18.9107 6.57987 17.8759 5.68984 16.646 5.48886C15.1277 5.24687 13.4779 5.25917 12.1165 5.49296C10.1996 5.82109 9.85182 6.50605 9.85182 7.77343V9.44276H14.3855V10.0006H8.15115C6.83217 10.0006 5.6786 10.7676 5.31811 12.2236C4.90249 13.893 4.88552 14.9348 5.31811 16.6779C5.64043 17.974 6.40807 18.901 7.72704 18.901H9.28351V16.8994C9.28351 15.4516 10.577 14.176 12.1165 14.176ZM11.8324 8.32714C11.3616 8.32714 10.9799 7.9539 10.9799 7.49452C10.9842 7.03105 11.3616 6.6578 11.8324 6.6578C12.2989 6.6578 12.6849 7.03515 12.6849 7.49452C12.6849 7.9539 12.3032 8.32714 11.8324 8.32714Z"
        fill="#FFFEFC"
        fillOpacity="0.97"
      />
    </g>
    <defs>
      <clipPath id="clip0_2010_5754">
        <rect width="19" height="21" fill="white" transform="translate(5 4)" />
      </clipPath>
    </defs>
  </>
);
