import * as zod from 'zod';

import { OnRobot2FG14State } from '../../OnRobot2FG14/types';
import { OnRobot2FG7State } from '../../OnRobot2FG7/types';
import { OnRobot3FG15State } from '../../OnRobot3FG15/types';

const CompatibleState = zod.union([
  OnRobot2FG7State,
  OnRobot2FG14State,
  OnRobot3FG15State,
]);

export const OnRobotDualQuickChangerState = zod.object({
  kind: zod.literal('OnRobotDualQuickChanger'),
  primary: CompatibleState.optional(),
  secondary: CompatibleState.optional(),
});

export type OnRobotDualQuickChangerState = zod.infer<
  typeof OnRobotDualQuickChangerState
>;
