import type * as zod from 'zod';

import { saveCodeBlocksOnDisk } from '@sb/local-build-data';
import { FailureKind } from '@sb/routine-runner';

import Step, { type StepPlayArguments } from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

export default class CodeBlockStep extends Step<Arguments, Variables> {
  public static areSubstepsRequired = false;

  public static Arguments = Arguments;

  public static Variables = Variables;

  protected initializeVariableState(): void {
    this.variables = {};
  }

  public async _play({ fail }: StepPlayArguments): Promise<void> {
    if (!this.routineContext.loadedRoutineState) {
      return fail({
        failure: {
          kind: FailureKind.StepPlayFailure,
          stepKind: 'CodeBlock',
        },
        failureReason: 'Routine State not found',
      });
    }

    try {
      // create code block in local folder
      await saveCodeBlocksOnDisk(
        this.args.codeBlockId,
        this.args.codeBlockCode,
      );
    } catch (err) {
      return fail({
        failure: {
          kind: FailureKind.StepPlayFailure,
          stepKind: 'CodeBlock',
        },
        error: err,
        failureReason: 'Could not access CodeBlock folder',
      });
    }

    try {
      await this.routineContext.runHostCommand({
        command: 'codeBlocks',
        codeBlockPath: this.args.codeBlockId,
        routineId: this.routineContext.loadedRoutineState.id,
        robotToken: this.routineContext.robotToken,
      });
    } catch (err) {
      return fail({
        failure: {
          kind: FailureKind.StepPlayFailure,
          stepKind: 'CodeBlock',
        },
        error: err,
        failureReason: 'An error occurred while running the CodeBlock',
      });
    }
  }
}
