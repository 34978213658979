import * as zod from 'zod';

import {
  ActionRequiredError,
  DistanceUnit,
  Step,
} from '@sb/remote-control/types';
import { getDistanceUnitInfo } from '@sb/remote-control/util/distance';
import { validateJSExpression } from '@sb/remote-control/util/expressions/validateJSExpression';
import { Expression } from '@sb/routine-runner';

export namespace AddOffsetStep {
  export const name = 'Add offset';
  export const isDecorator = true;
  export const description = 'Offset all movements within step';
  export const librarySection = Step.LibrarySection.Control;
  export const argumentKind = 'AddOffset';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    distanceUnit: DistanceUnit.default('meter'),
    translationX: Expression.optional(),
    translationY: Expression.optional(),
    translationZ: Expression.optional(),
    frame: zod.string().default('base'),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const validator: Step.Validator = ({
    step,
    stepConfiguration,
    routine: { space },
    globalSpace,
  }) => {
    const args = stepConfiguration?.args;

    if (args?.argumentKind !== argumentKind) {
      return;
    }

    if (!step.steps.some((nestedStep) => nestedStep.stepKind === 'MoveArmTo')) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Add Offset requires a Move Arm step',
      });
    }

    const allSpace = [...globalSpace, ...space];

    if (
      args.frame !== 'base' &&
      !allSpace.some((item) => item.id === args.frame && item.kind === 'plane')
    ) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Selected frame is not valid',
      });
    }
  };

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    validateJSExpression(args.translationX, 'translationX', 'X');
    validateJSExpression(args.translationY, 'translationY', 'Y');
    validateJSExpression(args.translationZ, 'translationZ', 'Z');

    const { multiplier } = getDistanceUnitInfo(args.distanceUnit);

    return {
      ...stepData,
      stepKind: 'AddOffset',
      args: {
        multiplier: 1 / multiplier,
        translationX: args.translationX,
        translationY: args.translationY,
        translationZ: args.translationZ,
        frame: args.frame,
      },
    };
  };

  export const getStepDescription: Step.GetStepDescription = ({
    stepConfiguration: { args },
    includeStepName,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      return null;
    }

    const unitSuffix = { millimeter: 'mm', inch: 'in', meter: 'm' }[
      args.distanceUnit
    ];

    const isNumberLitOrEmpty = (expr?: Expression) => {
      return (
        expr == null ||
        (expr.kind === 'JavaScript' &&
          expr.expression.match(/^-?[0-9]+(\.[0-9]+)?$/) != null)
      );
    };

    // if any of the (present) x, y, z offsets aren't literal numbers, don't
    // try to describe the step
    if (
      ![args.translationX, args.translationY, args.translationZ].every(
        isNumberLitOrEmpty,
      )
    ) {
      return null;
    }

    const forAxis = (axis: string, distanceExpr?: Expression) => {
      if (distanceExpr == null) {
        return null;
      }

      // only handle JS expressions for now
      if (distanceExpr.kind !== 'JavaScript') {
        return null;
      }

      // skip offsets of zero
      const value = parseFloat(distanceExpr.expression);

      if (value === 0) {
        return null;
      }

      return `${distanceExpr.expression}${unitSuffix} in ${axis}`;
    };

    const parts = [
      forAxis('X', args.translationX),
      forAxis('Y', args.translationY),
      forAxis('Z', args.translationZ),
    ].filter((p) => p != null);

    // if all offsets are zero, don't show anything
    if (parts.length === 0) {
      return null;
    }

    return `${includeStepName ? 'Add offset ' : ''}of ${parts.join(', ')}`;
  };
}

AddOffsetStep satisfies Step.StepKindInfo;
