/**
 * Quaternion:
 * An orientation specified as 4 numbers. Common geometry primitive across many
 * disciplines, including robotics, game development, and animation.
 *
 * Rather than using 3 numbers (roll, pitch, yaw) for orientation, we tend to
 * use 4 numbers (a quaternion) which has a number of computational benefits.
 *
 * Standard Bots refers to quaternion components as w, i, j, and k
 * rather than w, x, y, and z (which is more typical) in order to avoid
 * collisions with position. CartesianPose collapses position and orientation
 * into one struct, so this is very beneficial for our APIs
 */
import { Quaternion as ThreeQuaternion, Euler } from 'three';
import * as zod from 'zod';

export const QUATERNION_COMPONENT_MIN = -1;
export const QUATERNION_COMPONENT_MAX = 1;

const QuaternionComponent = zod
  .number()
  .min(QUATERNION_COMPONENT_MIN)
  .max(QUATERNION_COMPONENT_MAX);

export const Quaternion = zod.object({
  // the w component of the quaternion
  w: QuaternionComponent,
  // the x component of the quaternion
  i: QuaternionComponent,
  // the y component of the quaternion
  j: QuaternionComponent,
  // the z component of the quaternion
  k: QuaternionComponent,
});

export const IdentityQuaternion = {
  w: 0,
  i: 0,
  j: 0,
  k: 0,
};

export const EulerAngleToQuaternion = ({
  x,
  y,
  z,
}: {
  x: number;
  y: number;
  z: number;
}): Quaternion => {
  const quaternion = new ThreeQuaternion();
  quaternion.setFromEuler(new Euler(x, y, z));

  return { i: quaternion.x, j: quaternion.y, k: quaternion.z, w: quaternion.w };
};

export const QuaternionToEulerAngle = (quaternion: Quaternion): Euler => {
  const threeQuaternion = new ThreeQuaternion(
    quaternion.i,
    quaternion.j,
    quaternion.k,
    quaternion.w,
  );

  const euler = new Euler();
  euler.setFromQuaternion(threeQuaternion);

  return euler;
};

export type Quaternion = zod.infer<typeof Quaternion>;

// Unit quaternions' components should be equal to 1 when squared and summed.
// However, when transferring across data formats and on computers, rounding
// errors occur.
//
// This is the tolerance of the squared sum of the components of a quaternion
// in order for it to be considered a unit quaternion.
export const UNIT_QUATERNION_SQUARED_COMPONENT_EPSILON = 1e-2;

function squaredSumOfComponents({ w, i, j, k }: Quaternion): number {
  return w ** 2 + i ** 2 + j ** 2 + k ** 2;
}

function isUnitQuaternion(quaternion: Quaternion) {
  const sum = squaredSumOfComponents(quaternion);

  return Math.abs(sum - 1) < UNIT_QUATERNION_SQUARED_COMPONENT_EPSILON;
}

export const UnitQuaternion = Quaternion.refine(
  isUnitQuaternion,
  (quaternion) => ({
    message: `Quaternion has a non-unit magnitude of ${Math.sqrt(
      squaredSumOfComponents(quaternion),
    )}`,
  }),
);

const HALF_SQRT2 = Math.SQRT1_2;

export const rotateAngle = (angle: number) =>
  new ThreeQuaternion(
    Math.sin(((angle / 2) * Math.PI) / 180),
    0,
    0,
    Math.cos(((angle / 2) * Math.PI) / 180),
  );

export const rotateAngleReverse = (angle: number) =>
  new ThreeQuaternion(
    0,
    Math.cos(((angle / 2) * Math.PI) / 180),
    Math.sin(((angle / 2) * Math.PI) / 180),
    0,
  );

export const CommonQuaternions = {
  ROTATE_NONE: new ThreeQuaternion(0, 0, 0, 1),

  /** Quaternion to rotate +90° on X axis */
  ROTATE_X_90: new ThreeQuaternion(HALF_SQRT2, 0, 0, HALF_SQRT2),
  /** Quaternion to rotate 180° on X axis */
  ROTATE_X_180: new ThreeQuaternion(1, 0, 0, 0),
  /** Quaternion to rotate -90° on X axis */
  ROTATE_X_270: new ThreeQuaternion(-HALF_SQRT2, 0, 0, HALF_SQRT2),

  /** Quaternion to rotate +90° on Y axis */
  ROTATE_Y_90: new ThreeQuaternion(0, HALF_SQRT2, 0, HALF_SQRT2),
  /** Quaternion to rotate 180° on Y axis */
  ROTATE_Y_180: new ThreeQuaternion(0, 1, 0, 0),
  /** Quaternion to rotate -90° on Y axis */
  ROTATE_Y_270: new ThreeQuaternion(0, -HALF_SQRT2, 0, HALF_SQRT2),

  /** Quaternion to rotate +90° on Y axis */
  ROTATE_Z_90: new ThreeQuaternion(0, 0, HALF_SQRT2, HALF_SQRT2),
  /** Quaternion to rotate 180° on Z axis */
  ROTATE_Z_180: new ThreeQuaternion(0, 0, 1, 0),
  /** Quaternion to rotate -90° on Z axis */
  ROTATE_Z_270: new ThreeQuaternion(0, 0, -HALF_SQRT2, HALF_SQRT2),

  /** Quaternion to rotate 90° on X then 90° on Y */
  ROTATE_XY_90: new ThreeQuaternion(0.5, 0.5, 0.5, 0.5),
};
