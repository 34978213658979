/**
 * Wrap an async function so it waits for previous calls to complete before running
 */
export function mutex<Fn extends (...args: any[]) => Promise<any>>(fn: Fn): Fn {
  let promise = Promise.resolve();

  return (async (...args) => {
    let onComplete = () => {};

    const previousPromise = promise;

    promise = new Promise((resolve) => {
      onComplete = resolve;
    });

    try {
      await previousPromise;

      // we need to await before calling the onComplete
      return await fn(...args);
    } finally {
      onComplete();
    }
  }) as Fn;
}
