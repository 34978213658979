import * as zod from 'zod';

import { ActionRequiredError, Step } from '@sb/remote-control/types';
import { WaitForConfirmationModes } from '@sb/routine-runner/types/WaitForConfirmation';

export namespace WaitForConfirmationStep {
  export const name = 'Prompt User';
  export const description =
    'Routines are paused. The user will be prompted to take an action.';
  export const librarySection = Step.LibrarySection.InterfaceWithOperators;

  export const argumentKind = 'WaitForConfirmation';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    delay: zod.number().gte(0).default(5),
    message: zod.string(),
    mode: WaitForConfirmationModes.default('confirm'),
    choices: zod.array(zod.string()).default([]),
    required: zod.boolean().default(false),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    if (!args.message) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Messsage is not configured',
        fieldId: 'message',
      });
    }

    return {
      ...stepData,
      stepKind: 'WaitForConfirmation',
      args: {
        delayMS: args.delay * 1000,
        message: args.message,
        mode: args.mode,
        choices: args.choices,
        required: args.required,
      },
    };
  };

  export const getStepDescription: Step.GetStepDescription = ({
    stepConfiguration,
  }) => {
    if (stepConfiguration?.stepKind !== argumentKind) return null;
    const msg = stepConfiguration?.args?.message;

    if (msg != null && msg !== '') {
      return `Ask "${msg}"`;
    }

    return null;
  };

  export const validator: Step.Validator = ({ stepConfiguration }) => {
    if (
      stepConfiguration?.stepKind === argumentKind &&
      stepConfiguration?.args?.mode === 'choice'
    ) {
      if (stepConfiguration?.args?.choices.length === 0) {
        throw new ActionRequiredError({
          kind: 'invalidConfiguration',
          message: 'You must specify at least one choice.',
          fieldId: 'choices',
        });
      }

      if (stepConfiguration?.args?.choices.some((c) => c === '')) {
        throw new ActionRequiredError({
          kind: 'invalidConfiguration',
          message:
            'Options must not be empty. Delete empty options or fill them in.',
          fieldId: 'choices',
        });
      }

      if (
        stepConfiguration?.args?.choices.length !==
        new Set(stepConfiguration?.args?.choices).size
      ) {
        throw new ActionRequiredError({
          kind: 'invalidConfiguration',
          message: 'All options must be unique.',
          fieldId: 'choices',
        });
      }
    }
  };
}

WaitForConfirmationStep satisfies Step.StepKindInfo;
