import * as zod from 'zod';

import { IntegrationControlBoxIOInterface } from './IntegrationControlBoxIOInterface';
import { IntegrationHaasNGCInterface } from './IntegrationHaasNGCInterface';
import { IntegrationModbusTCPInterface } from './IntegrationModbusTCPInterface';

export const IntegrationInterface = zod.discriminatedUnion('kind', [
  IntegrationControlBoxIOInterface,
  IntegrationModbusTCPInterface,
  IntegrationHaasNGCInterface,
]);

export type IntegrationInterface = zod.infer<typeof IntegrationInterface>;
