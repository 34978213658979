import * as zod from 'zod';

import { ActionRequiredError, Step } from '@sb/remote-control/types';

export namespace TriggerFaultStep {
  export const name = 'Trigger Fault';
  export const description =
    'Allows user to put the robot into a failed/error state';
  export const librarySection = Step.LibrarySection.Control;
  export const argumentKind = 'TriggerFault';
  export const featureFlag = 'userFaults';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    message: zod.string().default(''),
    isRecoverable: zod.boolean().default(false),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    if (!args.message) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Messsage is not configured',
        fieldId: 'message',
      });
    }

    return {
      ...stepData,
      stepKind: 'TriggerFault',
      args: {
        message: args.message,
        isRecoverable: args.isRecoverable,
      },
    };
  };

  export const getStepDescription: Step.GetStepDescription = ({
    stepConfiguration: { args },
  }) => {
    if (args?.argumentKind !== argumentKind) {
      return null;
    }

    if (!args.message && !args.isRecoverable) {
      return 'Stop routine with message';
    }

    if (!args.message && args.isRecoverable) {
      return 'Pause routine with message';
    }

    return `Stop routine with message "${args.message}"`;
  };
}

TriggerFaultStep satisfies Step.StepKindInfo;
