import * as zod from 'zod';

import { ActionRequiredError, Step } from '@sb/remote-control/types';
import type { StepKind } from '@sb/routine-runner';

export namespace RunInBackgroundStep {
  export const name = 'Run in background';
  export const description =
    'Run substeps in the background while also continuing with the steps immediately after this block';
  export const isDecorator = true;
  export const librarySection = Step.LibrarySection.Control;
  export const argumentKind = 'RunInBackground';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const validator: Step.Validator = ({ step }) => {
    if (step.stepKind !== argumentKind) {
      return;
    }

    const INVALID_CHILD_KINDS: StepKind[] = [
      'AddOffset',
      'MoveArmTo',
      'PressButton',
      'WaitForConfirmation',
    ];

    const hasInvalidChild = (parentStep: Step.ConvertedSummary): boolean => {
      return parentStep.steps.some(
        (childStep) =>
          INVALID_CHILD_KINDS.includes(childStep.stepKind) ||
          hasInvalidChild(childStep),
      );
    };

    if (hasInvalidChild(step)) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message:
          'Run in Background steps may not contain Add Offset, Move Arm, Press Button or Prompt User steps.',
      });
    }
  };

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    return {
      ...stepData,
      stepKind: 'RunInBackground',
      args: {},
    };
  };
}

RunInBackgroundStep satisfies Step.StepKindInfo;
