import * as zod from 'zod';

export const waitForConfirmationModesArray = [
  'confirm',
  'choice',
  'freeform',
] as const;
export const WaitForConfirmationModes = zod.enum(waitForConfirmationModesArray);
export type WaitForConfirmationModes = zod.infer<
  typeof WaitForConfirmationModes
>;
