import * as zod from 'zod';

import { ActionRequiredError, Step } from '@sb/remote-control/types';

/**
 * Types matching the routine runner schema for "Code Blocks" operations.
 */
export namespace CodeBlockStep {
  export const name = 'Code Block';
  export const description = 'Perform advanced custom logic in code';
  export const isDecorator = false;
  export const librarySection = Step.LibrarySection.Control;
  export const librarySort = '100';
  export const argumentKind = 'CodeBlock';
  export const featureFlag = 'enableCodeBlocks';

  /** This interface is used when either "loop forever" or "loop N times" are selected. */
  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    codeBlockId: zod.string().optional(),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  // this function is executed in two different contexts
  // when we do step validation in remote control
  // and when we actually run the routine
  // when we do step validation, we don't have the list of codeBlocks
  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
    codeBlocks,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    if (!args.codeBlockId) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Code Block required',
        fieldId: 'codeBlockId',
      });
    }

    // we are not fetching code blocks when running validation
    const codeBlock = codeBlocks?.find((c) => c.id === args.codeBlockId);

    return {
      ...stepData,
      stepKind: 'CodeBlock',
      args: {
        codeBlockId: args.codeBlockId,
        codeBlockCode: codeBlock?.code || '',
      },
    };
  };

  export const getStepDescription: Step.GetStepDescription = ({
    stepConfiguration: { args },
  }) => {
    if (args?.argumentKind !== argumentKind) {
      return null;
    }

    return `Execute Code Block`;
  };
}

CodeBlockStep satisfies Step.StepKindInfo;
