import cx from 'classnames';

export type ButtonVariant = 'Filled' | 'Elevated' | 'Gray' | 'Flat';

export const BUTTON_VARIANT_CLASSES: Record<ButtonVariant, string> = {
  Filled: cx(
    'tw-shadow-30',
    'disabled:tw-shadow-none',
    'disabled:tw-bg-fill-primary',
    'focus-visible:tw-outline',
  ),
  Elevated: cx(
    'tw-shadow-30',
    'disabled:tw-shadow-none',
    'disabled:tw-bg-fill-primary',
    'focus-visible:tw-outline',
  ),
  Gray: '',
  Flat: '',
};
