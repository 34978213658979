import * as zod from 'zod';

import { IntegrationInterfaceKind } from './IntegrationInterfaceKind';

export const IntegrationModbusTCPInterface = zod.object({
  kind: zod.literal(IntegrationInterfaceKind.Values.modbusTCP),
  hostname: zod.string().default(''),
  port: zod.number().default(502),
  timeoutMS: zod.number().default(2000),
});

export type IntegrationModbusTCPInterface = zod.infer<
  typeof IntegrationModbusTCPInterface
>;

export function isIntegrationModbusTCPInterface(
  i: any,
): i is IntegrationModbusTCPInterface {
  return i != null && i.kind === IntegrationInterfaceKind.Values.modbusTCP;
}
