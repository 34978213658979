import { useEffect } from 'react';
import { create } from 'zustand';

import { makeLogNamespace } from '@sb/log/log';
import type { Space } from '@sb/routine-runner';
import { createGlobalSpace } from '@sbrc/services/feathers-client/global-space/createGlobalSpace';
import { deleteGlobalSpace } from '@sbrc/services/feathers-client/global-space/deleteGlobalSpace';
import { onGetAllGlobalSpace } from '@sbrc/services/feathers-client/global-space/onGetAllGlobalSpace';
import { updateGlobalSpace } from '@sbrc/services/feathers-client/global-space/updateGlobalSpace';

const log = makeLogNamespace('useGlobalSpaceStore');

interface GlobalSpaceStore {
  items: Space.Item[];
  itemIDToGlobalKey: { [key: string]: string };
  addItem: (item: Space.Item) => void;
  updateItem: (item: Space.Item) => void;
  removeItem: (itemID: string) => void;
}

export const useGlobalSpaceStore = create<GlobalSpaceStore>((_set, get) => {
  const addItem = async (item: Space.Item) => {
    try {
      await createGlobalSpace(item);
    } catch (e) {
      log.error('addItem.error', 'Global space item did not add', { e });
    }
  };

  const updateItem = async (item: Space.Item) => {
    try {
      const globalItemID = get().itemIDToGlobalKey[item.id];
      await updateGlobalSpace(globalItemID, item);
    } catch (e) {
      log.error('updateItem.error', 'Global space item did not update', { e });
    }
  };

  const removeItem = async (itemID: string) => {
    try {
      const globalItemID = get().itemIDToGlobalKey[itemID];
      await deleteGlobalSpace(globalItemID);
    } catch (e) {
      log.error('removeItem.error', 'Global space item did not remove', { e });
    }
  };

  return {
    items: [],
    itemIDToGlobalKey: {},
    addItem,
    updateItem,
    removeItem,
  };
});

export function useInitializeGlobalSpace() {
  useEffect(() => {
    const unsubscribeGlobalSpace = onGetAllGlobalSpace(
      (globalSpaceItemsWithID) => {
        const itemIDToGlobalKey: { [key: string]: string } = {};
        const globalSpaceItems = [];

        for (const itemWithID of globalSpaceItemsWithID) {
          itemIDToGlobalKey[itemWithID.spaceItem.id] = itemWithID.globalSpaceID;
          globalSpaceItems.push(itemWithID.spaceItem);
        }

        useGlobalSpaceStore.setState({
          items: globalSpaceItems,
          itemIDToGlobalKey,
        });
      },
    );

    return unsubscribeGlobalSpace;
  }, []);
}
