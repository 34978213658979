import * as zod from 'zod';

import {
  IntegrationActionGroup,
  IntegrationInterface,
  INTEGRATION_CONTROL_BOX_IO_INTERFACE_DEFAULT,
} from '@sb/integrations/io/types';

import {
  createGenericAutodoor,
  createGenericCycle,
  createGenericMachineStatus,
  createGenericWorkholding,
} from './createDefault';
import { MachineStatusConfig } from './MachineStatus';

export const LATHE = 'Lathe';
export const MILL = 'Mill';

export const CNCMachineConfiguration = zod.object({
  kind: zod.literal('CNCMachine'),
  name: zod.string().default('CNC Machine'),
  machineKind: zod.enum([LATHE, MILL]),
  interfaces: IntegrationInterface.array().default([
    INTEGRATION_CONTROL_BOX_IO_INTERFACE_DEFAULT,
  ]),
  cycle: IntegrationActionGroup.default(createGenericCycle()),
  machineStatus: MachineStatusConfig.default(createGenericMachineStatus()),
  autodoor: IntegrationActionGroup.default(createGenericAutodoor()),
  workholdings: IntegrationActionGroup.array().default([
    createGenericWorkholding('Vise', 0),
  ]),
});

export type CNCMachineConfiguration = zod.infer<typeof CNCMachineConfiguration>;
