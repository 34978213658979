import * as zod from 'zod';

import {
  OR2FG14FingerOrientation,
  OR_2FG14_FINGERTIP_OFFSET_METERS_DEFAULT,
  OR_2FG14_FINGER_ANGLE_DEGREES_DEFAULT,
  OR_2FG14_FINGER_POSITION_DEFAULT,
} from '../constants';

// database equipment configuration
export const OnRobot2FG14FingerConfiguration = zod
  .object({
    mountingPosition: OR2FG14FingerOrientation.default(
      OR_2FG14_FINGER_POSITION_DEFAULT,
    ),
    // offsetInMeters = fingertipDiameter
    offsetInMeters: zod
      .number()
      .default(OR_2FG14_FINGERTIP_OFFSET_METERS_DEFAULT),
    fingerAngle: zod.number().default(OR_2FG14_FINGER_ANGLE_DEGREES_DEFAULT),
  })
  .default({});

export type OnRobot2FG14FingerConfiguration = zod.infer<
  typeof OnRobot2FG14FingerConfiguration
>;
