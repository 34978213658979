import * as zod from 'zod';

export namespace ModbusTCP {
  export const RegisterFormat = zod.enum(['int', 'uint16', 'float']);

  export type RegisterFormat = zod.infer<typeof RegisterFormat>;

  export const WriteSingleRegister = zod.object({
    kind: zod.literal('WriteSingleRegister'),
    address: zod.number().default(0),
    value: zod.number().default(0),
    format: RegisterFormat.default('int'),
    parameter: zod.string().default(''),
  });

  export type WriteSingleRegister = zod.infer<typeof WriteSingleRegister>;

  export const RegisterValue = zod.object({
    width: zod.number().default(16),
    format: RegisterFormat.default('int'),
    isLE: zod.boolean().default(false),
    value: zod.number().default(0),
    parameter: zod.string().default(''),
  });

  export type RegisterValue = zod.infer<typeof RegisterValue>;

  export const WriteMultipleRegisters = zod.object({
    kind: zod.literal('WriteMultipleRegisters'),
    address: zod.number().default(0),
    values: RegisterValue.array().default([{}]),
  });

  export const CoilValue = zod.object({
    address: zod.number().default(0),
    value: zod.number().default(0),
  });

  export type CoilValue = zod.infer<typeof CoilValue>;

  export const WriteSingleCoil = zod.object({
    kind: zod.literal('WriteSingleCoil'),
    address: zod.number().default(0),
    value: zod.number().default(0),
  });

  export const WriteMultipleCoils = zod.object({
    kind: zod.literal('WriteMultipleCoils'),
    values: CoilValue.array().default([{}]),
  });

  export const Wait = zod.object({
    kind: zod.literal('Wait'),
    durationMS: zod.number().default(500),
  });

  export const Operation = zod.discriminatedUnion('kind', [
    WriteSingleRegister,
    WriteMultipleRegisters,
    WriteSingleCoil,
    WriteMultipleCoils,
    Wait,
  ]);

  export type Operation = zod.infer<typeof Operation>;
}
