import type { Robot } from '@sb/remote-control/types';
import {
  RelayPortID,
  InputPortID,
  OutputPortID,
  FlangeOutputPortID,
  FlangeInputPortID,
} from '@sb/routine-runner';
import { FLANGE_PINS } from '@sbrc/components/visualizer-view-shared/io/utils';

export function generateIOPort(
  portID: RelayPortID,
  portData?: Partial<Robot.RelayPort>,
): Robot.RelayPort;

export function generateIOPort(
  portID: OutputPortID,
  portData?: Partial<Robot.OutputPort>,
): Robot.OutputPort;

export function generateIOPort(
  portID: InputPortID,
  portData?: Partial<Robot.InputPort>,
): Robot.InputPort;

export function generateIOPort(
  portID: FlangeInputPortID,
  portData?: Partial<Robot.FlangeInputPort>,
): Robot.FlangeInputPort;

export function generateIOPort(
  portID: FlangeOutputPortID,
  portData?: Partial<Robot.FlangeOutputPort>,
): Robot.FlangeOutputPort;

export function generateIOPort(
  portID: string,
  portData?: Partial<Robot.IOPort>,
): Robot.IOPort | null;

export function generateIOPort(
  portID: string,
  portData?: Partial<Robot.IOPort>,
): Robot.IOPort | null {
  const parsedInputPort = InputPortID.safeParse(portID);
  const port = Number.parseInt(portID.replace(/[^\d]/g, ''), 10);

  if (parsedInputPort.success) {
    return {
      name: portID,
      shortName: `IN ${port}`,
      highSignalName: 'High',
      lowSignalName: 'Low',
      ...portData,
      kind: 'Input',
      portID: parsedInputPort.data,
      port,
    };
  }

  const parsedOutputPort = OutputPortID.safeParse(portID);

  if (parsedOutputPort.success) {
    return {
      name: portID,
      shortName: `OUT ${port}`,
      highSignalName: 'High',
      lowSignalName: 'Low',
      ...portData,
      kind: 'Output',
      portID: parsedOutputPort.data,
      port,
    };
  }

  const parsedRelayPort = RelayPortID.safeParse(portID);

  if (parsedRelayPort.success) {
    return {
      name: portID,
      shortName: `REL ${port}`,
      highSignalName: 'On',
      lowSignalName: 'Off',
      ...portData,
      kind: 'Relay',
      portID: parsedRelayPort.data,
      port,
    };
  }

  const parsedFlangeInputPortID = FlangeInputPortID.safeParse(portID);

  if (parsedFlangeInputPortID.success) {
    return {
      name: portID,
      shortName: `PIN ${FLANGE_PINS.indexOf(portID) + 1}`,
      highSignalName: 'High',
      lowSignalName: 'Low',
      ...portData,
      kind: 'FlangeInput',
      portID: parsedFlangeInputPortID.data,
      port,
    };
  }

  const parsedFlangeOutputPortID = FlangeOutputPortID.safeParse(portID);

  if (parsedFlangeOutputPortID.success) {
    return {
      name: portID,
      shortName: `PIN ${FLANGE_PINS.indexOf(portID) + 1}`,
      highSignalName: 'High',
      lowSignalName: 'Low',
      ...portData,
      kind: 'FlangeOutput',
      portID: parsedFlangeOutputPortID.data,
      port,
    };
  }

  return null;
}
